import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import FlowChart from '../dist/img/flowchart.png'

const ProcessFlowChart = () => {
  return (
    <div>
        <Header />
        <div className="content-wrapper bg-white">
            <div className="content-header">
                <div className="container">
                    <div>
                        <h3 className='pt-5 text-center'>Process Flow Chart</h3>
                    </div>
                    <div className='text-center'>
                        <img src={FlowChart} className="product-image w-75 mt-3" alt="Product Image" style={{border: 'solid 2px grey'}}/>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default ProcessFlowChart
import React from 'react'
import NHALogo from "../dist/img/nha-logo.png"
import AitechManual from "../dist/files/AitechManual2024.pdf"

const Header = () => {
  return (
    <div>
        <nav className="main-header navbar navbar-expand-md navbar-light navbar-white">
        <div className="container">
            <a href="/" className="navbar-brand">
            <img src={NHALogo} alt="AdminLTE Logo" className="brand-image img-circle elevation-2" style={{opacity: '.8'}} />
            <span className="brand-text font-weight-bold ml-2">AITECH</span>
            </a>
            <button className="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse order-3" id="navbarCollapse">
            {/* Left navbar links */}
            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                <a href="/" className="nav-link">Home</a>
                </li>
                <li className="nav-item dropdown">
                <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">About</a>
                <ul aria-labelledby="dropdownSubMenu1" className="dropdown-menu border-0 shadow">
                    <li><a href='#/Objectives' className="dropdown-item">Objectives</a></li>
                    <li><a href='#/TheCommittee' className="dropdown-item">The Committee</a></li>
                </ul>
                </li>
                <li className="nav-item">
                <a href="#/AccreditedTechnologies" className="nav-link">Accredited Technologies</a>
                </li>
                <li className="nav-item dropdown">
                <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">Application for Accreditation</a>
                <ul aria-labelledby="dropdownSubMenu1" className="dropdown-menu border-0 shadow">
                    <li><a href="#/ChecklistRequirement" className="dropdown-item">Checklist for Requirements</a></li>
                    <li><a href="#/ProcessFlowChart" className="dropdown-item">Process Flow Chart</a></li>
                    <li><a href="#/FeesRequired" className="dropdown-item">Fees Required</a></li>
                    <li><a href="#/ApplyAccreditation" className="dropdown-item">Apply for AITECH Accreditation</a></li>
                </ul>
                </li>
                <li className="nav-item dropdown">
                <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">Resources</a>
                <ul aria-labelledby="dropdownSubMenu1" className="dropdown-menu border-0 shadow">
                    <li><a href={AitechManual} target="blank" className="dropdown-item">AITECH Manual</a></li>
                    <li><a href="#/OtherReferences" className="dropdown-item">Other References</a></li>
                </ul>
                </li>
                <li className="nav-item">
                <a href="#/ContactUs" className="nav-link">Contact Us</a>
                </li>
            </ul>
            </div>
        </div>
        </nav>

    </div>
  )
}

export default Header

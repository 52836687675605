import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import fillUpForm from "../dist/img/fillUpForm.png"
import uploadForm from "../dist/img/uploadForm.png"
import checkEmail from "../dist/img/checkEmail.png"
import fileUpload from "../dist/img/fileUpload.png"
import flowchartView from "../dist/img/flowchartView.png"

const ApplyAccreditation = () => {
  return (
    <div>
        <Header />
        <div className="content-wrapper bg-white">
            <div className="content-header">
                <div className="container">
                    <h3 className='m-3'>Apply for AITECH Accreditation</h3>
                    <div className='row'>
                      <div className='col-sm-4'>
                        <div className='card m-3' style={{height: '250px', borderRadius: "15px", backgroundColor: "#f4f4f4"}}>
                          <div className='card-body'>
                            <div style={{display: 'flex'}}>
                              <img src={fillUpForm} alt="AdminLTE Logo" className="img-circle" style={{width: '80px', height: '80px'}} />
                              <div>
                                <h4 className='m-2'>STEP 1</h4>
                                <p className='ml-2'>Fill out the form. Click <a href=''>here</a></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-sm-4'>
                        <div className='card m-3' style={{height: '250px', borderRadius: "15px", backgroundColor: "#f4f4f4"}}>
                          <div className='card-body'>
                            <div style={{display: 'flex'}}>
                              <img src={uploadForm} alt="AdminLTE Logo" className="img-circle" style={{width: '80px', height: '80px'}} />
                              <div>
                                <h4 className='m-2'>STEP 2</h4>
                                <p className='ml-2'>Upload the  Required Documents on the Drive Provided by the Secretariat sent in your email.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-sm-4'>
                        <div className='card m-3' style={{height: '250px', borderRadius: "15px", backgroundColor: "#f4f4f4"}}>
                          <div className='card-body'>
                            <div style={{display: 'flex'}}>
                              <img src={checkEmail} alt="AdminLTE Logo" className="img-circle" style={{width: '80px', height: '80px'}} />
                              <div>
                                <h4 className='m-2'>STEP 3</h4>
                                <p className='ml-2'>Upload the  Required Documents on the Drive Provided by the Secretariat sent in your email.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-sm-4'>
                        <div className='card m-3' style={{height: '250px', borderRadius: "15px", backgroundColor: "#f4f4f4"}}>
                          <div className='card-body'>
                            <div style={{display: 'flex'}}>
                              <img src={fileUpload} alt="AdminLTE Logo" className="img-circle" style={{width: '80px', height: '80px'}} />
                              <div>
                                <h4 className='m-2'>STEP 4</h4>
                                <p className='ml-2'>Once documents are complete, send 2 sets of hard copies and one (1) set of soft copies of the documents to the AITECH Secretariat Office.
</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-sm-4'>
                        <div className='card m-3' style={{height: '250px', borderRadius: "15px", backgroundColor: "#f4f4f4"}}>
                          <div className='card-body'>
                            <div style={{display: 'flex'}}>
                              <img src={flowchartView} alt="AdminLTE Logo" className="img-circle" style={{width: '80px', height: '80px'}} />
                              <div>
                                <h4 className='m-2'>STEP 5</h4>
                                <p className='ml-2'>See Process Flow Chart for further information on the AITECH Accreditation Process.
                                  Check email for updates and queries/clarifications from the AITECH Secretariat.  
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>


        <Footer /> 
    </div>
  )
}

export default ApplyAccreditation
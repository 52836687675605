import React from 'react'
import DTI from '../dist/img/DTI.png'
import DHSUD from '../dist/img/DHSUD.png'
import CIAP from '../dist/img/CIAP.png'
import DOST from '../dist/img/DOST.png'
import DPWH from '../dist/img/DPWH.png'
import BALAI from '../dist/img/BALAI.png'
import NHMFC from '../dist/img/NHMFC.png'
import PAGIBIG from '../dist/img/pagibig.png'
import UP from '../dist/img/up.png'
import NHA from '../dist/img/nha.png'

const FooterCommittee = () => {
  return (
    <div>
        <footer className="main-footer" style={{backgroundColor: '#f3f6fb'}}>
            <div className='container'>
                <div className='row mt-3'>
                    <div className='col-sm-1 text-center p-2'></div>
                    <div className='col-sm-2 text-center p-2'> <img src={DTI} className="product-image w-50" alt="Product Image" /> </div>
                    <div className='col-sm-2 text-center p-2'> <img src={DHSUD} className="product-image w-50" alt="Product Image" /> </div>
                    <div className='col-sm-2 text-center p-2'> <img src={CIAP} className="product-image w-50" alt="Product Image" /> </div>
                    <div className='col-sm-2 text-center p-2'> <img src={DOST} className="product-image w-50" alt="Product Image" /> </div>
                    <div className='col-sm-2 text-center p-2'> <img src={BALAI} className="product-image w-50" alt="Product Image" /> </div>
                    <div className='col-sm-1 text-center p-2'></div>
                </div>
                <div className='row mb-3'>
                    <div className='col-sm-1 text-center p-2'></div>
                    <div className='col-sm-2 text-center p-2'> <img src={DPWH} className="product-image w-50" alt="Product Image" /> </div>
                    <div className='col-sm-2 text-center p-2'> <img src={NHMFC} className="product-image w-50" alt="Product Image" /> </div>
                    <div className='col-sm-2 text-center p-2'> <img src={PAGIBIG} className="product-image w-50" alt="Product Image" /> </div>
                    <div className='col-sm-2 text-center p-2'> <img src={UP} className="product-image w-50" alt="Product Image" /> </div>
                    <div className='col-sm-2 text-center p-2'> <img src={NHA} className="product-image w-50" alt="Product Image" /> </div>
                    <div className='col-sm-1 text-center p-2'></div>
                </div>
            </div>
        </footer>
    </div>
  )
}

export default FooterCommittee
import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import * as BsIcons from "react-icons/bs";
import TextField from '@mui/material/TextField';
import Textarea from '@mui/joy/Textarea';
import Button from '@mui/material/Button';

const ContactUs = () => {
  return (
    <div>
         <Header/>
         <div className="content-wrapper bg-white">
            <div className="content-header">
                <div className="container ">
                    <div className='card p-4 mt-5 w-75 elevation-2' style={{backgroundColor: '#f4f4f4', margin: 'auto'}}>
                        <div className='row'>
                            <div className='col-sm-5'>
                                <h3>Contact Us</h3>
                                <div className='mt-4' style={{display: 'flex'}}>
                                    <BsIcons.BsFillTelephoneFill className='mt-1' style={{color: '#2782eb'}}/> <p className='ml-3'>(02) 8790-0800</p>   
                                </div>

                                <div className='mt-4' style={{display: 'flex'}}>
                                    <BsIcons.BsFillEnvelopeFill  className='mt-1' style={{color: '#2782eb'}}/> <p className='ml-3'>aitechsecretariat@Nnha.gov.ph</p>   
                                </div>

                                <div className='mt-3' style={{display: 'flex'}}>
                                    <BsIcons.BsFillPinMapFill  className='mt-1' style={{color: '#2782eb'}}/> <p className='ml-3'>5 Elliptical, Diliman, Quezon City, <br/> 1101 Metro Manila</p>   
                                </div>
                            </div>

                            <div className='col-sm-7'>
                                <iframe className='w-100' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1192.6724643048788!2d121.05295037801507!3d14.649624268932126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397b7121aa2c035%3A0x69c52993225ae111!2sNational%20Housing%20Authority%20(NHA)%20Main%20Office!5e0!3m2!1sen!2sph!4v1716357695295!5m2!1sen!2sph" height="370" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <Footer/>
    </div>
  )
}

export default ContactUs
import React from "react";
import { HashRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home"
import Objectives from "./pages/Objectives"
import TheCommittee from "./pages/TheCommittee"
import AccreditedTechnologies from "./pages/AccreditedTechnologies"
import ProcessFlowChart from "./pages/ProcessFlowChart"
import FeesRequired from "./pages/FeesRequired"
import ChecklistRequirement from "./pages/ChecklistRequirement"
import ApplyAccreditation from "./pages/ApplyAccreditation"
import OtherReferences from "./pages/OtherReferences"
import ContactUs from "./pages/ContactUs"

function App() {
  return (
    <HashRouter>
      <Routes>
          <Route>
            <Route path="/" element={<Home />}/>
            <Route exact path="Objectives" element={<Objectives />}/>
            <Route exact path="TheCommittee" element={<TheCommittee />}/>
            <Route exact path="AccreditedTechnologies" element={<AccreditedTechnologies />}/>
            <Route exact path="ProcessFlowChart" element={<ProcessFlowChart />}/>
            <Route exact path="FeesRequired" element={<FeesRequired />}/>
            <Route exact path="ApplyAccreditation" element={<ApplyAccreditation />}/>
            <Route exact path="ChecklistRequirement" element={<ChecklistRequirement />}/>
            <Route exact path="OtherReferences" element={<OtherReferences />}/>
            <Route exact path="ContactUs" element={<ContactUs />}/>
          </Route>
      </Routes>
    </HashRouter>
  );
}

export default App;

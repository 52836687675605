import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import WallPanel from "../dist/files/WallPanelMaterialNewApplication.pdf"
import SepticTankNewApplication from "../dist/files/SepticTankNewApplication.pdf"
import RoofingNewApplication from "../dist/files/RoofingNewApplication.pdf"
import MaterialOnlyNewApplication from "../dist/files/MaterialOnlyNewApplication.pdf"
import BuildingSystemNewApplication from "../dist/files/BuildingSystemNewApplication.pdf"
import RenewalofApplication from "../dist/files/RenewalofApplication.pdf"
import Button from '@mui/material/Button';
import * as BsIcons from "react-icons/bs";
import IconButton from '@mui/material/IconButton';
import rawmaterials from '../dist/img/rawmaterials.png'
import house from '../dist/img/house.png'
import building from '../dist/img/building.png'

const ChecklistRequirement = () => {
  return (
    <div>
    <Header/>
    <div className="content-wrapper bg-white">
        <div className="content-header">
            <div className="container">
                <h3 className='pt-4'>Checklist for Requirements</h3>

                <div className='row mt-4'>
                    <div className='col-sm-6'>
                        <div className='card'>
                            <div className='card-header'>
                                <div className='card-title font-weight-bold'>
                                    New Applications
                                </div>
                            </div>

                            <div className='card-body'>
                                <ul class="list-group list-group-unbordered mb-3">
                                    <li class="list-group-item border-top-0">
                                        Building System New Application <a href={BuildingSystemNewApplication} target="blank" class="float-right">View File</a>
                                    </li>
                                    <li class="list-group-item">
                                        Material Only New Application <a href={MaterialOnlyNewApplication} target="blank" class="float-right">View File</a>
                                    </li>
                                    <li class="list-group-item">
                                        Roofing New Application <a href={RoofingNewApplication} target="blank" class="float-right">View File</a>
                                    </li>
                                    <li class="list-group-item">
                                        	Septic Tank New Application <a href={SepticTankNewApplication} target="blank" class="float-right">View File</a>
                                    </li>
                                    <li class="list-group-item">
                                        Wall Panel_Material New Application <a href={WallPanel} target="blank" class="float-right">View File</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className='col-sm-6'>
                        <div className='card'>
                            <div className='card-header'>
                                <div className='card-title font-weight-bold'>
                                    Renewal Application
                                </div>
                            </div>

                            <div className='card-body'>
                                <ul class="list-group list-group-unbordered mb-3">
                                    <li class="list-group-item border-top-0">
                                        Renewal of Application <a href={RenewalofApplication} target="blank" class="float-right">View File</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Footer/>
    </div>
  )
}

export default ChecklistRequirement
import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import FooterCommittee from '../components/FooterCommittee'
import Img1 from '../dist/img/img-news-001.jpg'
import Img2 from '../dist/img/img-news-002.jpg'
import Vid1 from '../dist/img/Vid1.mp4'
import Banner1 from '../dist/img/home-banner-1.png'
import Carousel from 'react-bootstrap/Carousel';

const Home = () => {
  return (
    <div>
        <Header />
        <div className="content-wrapper bg-white">
            <div className="content-header">
                <div className="container">
                <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                        <img className="d-block w-100" src={Banner1} alt="First slide" />
                        </div>
                        {/* <div className="carousel-item">
                        <img className="d-block w-100" src={Banner1} alt="Second slide" />
                        </div>
                        <div className="carousel-item">
                        <img className="d-block w-100" src={Banner1} alt="Third slide" />
                        </div> */}
                    </div>
                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true" />
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true" />
                        <span className="sr-only">Next</span>
                    </a>
                </div>

                </div>
            </div>

            <section className='container'>
                    {/* START ANNOUNCEMENT */}
                    <div>
                        <div className='card mt-5' style={{backgroundColor: '#4285f4'}}>
                            <h4 className='font-weight-bold text-white pt-2 pb-1 pl-2'>ANNOUNCEMENT</h4>
                        </div>
                        <div className='mt-3'>
                            <div className='row p-2'>
                                <div className='col-sm-6'>
                                    <div className="row pr-3">
                                        <div className="col-12 col-sm-6 p-0">
                                            <img src={Img1} className="product-image" alt="Product Image" />
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div className='ml-2' style={{justifyContent: 'space-around'}}>
                                                <h3>AITECH SUBMISSION OF APPLICATION</h3>
                                                <p className='text-justify'>
                                                    Please submit your applications in person at the HTREX Building, NHA Main Compound, Elliptical Road, Diliman, Q.C., or by email to aitechsecretariat@gmail.com. Thank you.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-sm-6'>
                                    <div className="row pl-3">
                                        <div className="col-12 col-sm-6 p-0">
                                            <img src={Img2} className="product-image" alt="Product Image" />
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div className='ml-2' style={{justifyContent: 'space-around'}}>
                                                <h3>SCHEDULE FOR AITECH INTER-AGENCY</h3>
                                                <p className='text-justify'>As of now, June 26, 2024 is the tentative schedule of the AITECH Meeting.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* END ANNOUNCEMENT */}

                    {/* START VIDEO */}
                    <div className='card elevation-0 border-0 mt-5'>
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe class="embed-responsive-item" src={Vid1} allowfullscreen autoplay></iframe>
                        </div>
                    </div>
            </section>
        </div>
        <FooterCommittee />
        <Footer/>
    </div>
  )
}

export default Home
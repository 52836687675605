import React from 'react'

const Footer = () => {
  return (
    <div>
      <footer className="main-footer text-white" style={{backgroundColor: '#1f438c'}}>
        {/* To the right */}
        <div className="float-right d-none d-sm-inline font-weight-bold">
          AITECH
        </div>
        {/* Default to the left */}
        <strong className='text-white'>Copyright © 2024 <a href="https://nha.gov.ph/" style={{color: '#eff2f7'}}>NATIONAL HOUSING AUTHORITY</a>.</strong> All rights reserved.
      </footer>
    </div>
  )
}

export default Footer
import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import rawmaterials from '../dist/img/rawmaterials.png'
import house from '../dist/img/house.png'
import building from '../dist/img/building.png'


const FeesRequired = () => {
  return (
    <div>
        <Header/>
        <div className="content-wrapper bg-white">
            <div className="content-header">
                <div className="container">
                    <h3 className='m-3'>Fees Required</h3>
                    <div className='row mt-4'>
                        <div className='col-sm-4 mt-3'>
                            <div className='card text-center' style={{height: '95px', borderRadius: '50px', backgroundColor: "#f4f4f4", margin: 'auto'}}>
                                <div className='p-2' style={{display: 'flex'}}>
                                    <img src={rawmaterials} alt="AdminLTE Logo" className="img-circle ml-1" style={{width: '80px', height: '80px'}} />

                                    <div className='m-2 mt-3 text-left'>
                                        <h4 className='mb-0'>Material Only</h4>
                                        <h5 className='font-weight-normal'>Php 20,403.72</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-sm-4 mt-3'>
                            <div className='card text-center' style={{height: '95px', borderRadius: '50px', backgroundColor: "#f4f4f4", margin: 'auto'}}>
                                <div className='p-2' style={{display: 'flex'}}>
                                    <img src={house} alt="AdminLTE Logo" className="img-circle ml-1" style={{width: '80px', height: '80px'}} />

                                    <div className='m-2 mt-3 text-left'>
                                        <h4 className='mb-0'>Material Only</h4>
                                        <h5 className='font-weight-normal'>Php 20,403.72</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-sm-4 mt-3'>
                            <div className='card text-center' style={{height: '95px', borderRadius: '50px', backgroundColor: "#f4f4f4", margin: 'auto'}}>
                                <div className='p-2' style={{display: 'flex'}}>
                                    <img src={building} alt="AdminLTE Logo" className="img-circle ml-1" style={{width: '80px', height: '80px'}} />

                                    <div className='m-2 mt-3 text-left'>
                                        <h4 className='mb-0'>Material Only</h4>
                                        <h5 className='font-weight-normal'>Php 30,122.82</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default FeesRequired
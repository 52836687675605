import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import DHSUD from '../dist/img/DHSUD.png'
import NHA from '../dist/img/nha.png'
import DOST from '../dist/img/DOST.png'
import PAGIBIG from '../dist/img/pagibig.png'
import DTI from '../dist/img/DTI.png'
import CIAP from '../dist/img/CIAP.png'
import DPWH from '../dist/img/DPWH.png'
import BALAI from '../dist/img/BALAI.png'
import NHMFC from '../dist/img/NHMFC.png'
import UP from '../dist/img/up.png'

const TheCommittee = () => {
  return (
    <div>
        <Header/>
        <div className="content-wrapper bg-white">
            <div className="content-header">
                <div className="container">
                    <section>
                        <h3 className='pt-5'>The Committee Members</h3>
                        <p className='text-justify pt-3 text-md'>
                            The AITECH Committee acts as a review and approving body for applications for accreditation of
                            innovative technologies appropriate for housing. Its main objective is to assist producers of innovative
                            technologies in securing acceptance of their products or systems in the market as well as to make
                            these technologies acceptable for funding under government housing loan programs.
                        </p>

                        <p className='text-justify pt-3 text-md'>
                            The Committee also aims to encourage and promote the use of innovative technologies as an alternative to
                            traditional housing construction system; and to develop standards, guidelines, and procedures for
                            accreditation system.
                        </p>
                    </section>

                    <section className='pt-5'>
                        <div className='w-75' style={{margin: 'auto'}}>
                            <div className='text-center p-2 text-white mt-5'style={{backgroundColor: "#21418c" , borderRadius: "7px"}}>
                                <h4>Department of Human Settlements and Urban Development (DHSUD)</h4>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-sm-4 text-center mt-3 mb-3'>
                                    <img src={DHSUD} className="product-image w-75 mt-3" alt="Product Image" />
                                </div>

                                <div className='col-sm-8 mt-3 mb-3'>
                                    <label className='font-weight-normal'>Chairperson:</label>
                                    <h5 className='mb-0'>ARCH. HENRY L. YAP</h5>
                                    <p className='text-muted'>Undersecretary</p>

                                    <p className='text-muted mb-0 pt-3'>Alternate Representative:</p>
                                    <h5>ATTY. ANGELITO F. AGUILA</h5>

                                    <p className='text-muted mb-0 pt-3'>Technical Representative:</p>
                                    <h5>ENGR. ANNOEL B. BAUTISTA</h5>

                                    <p className='text-justify pt-3 text-md'><b>Function:</b> &nbsp;
                                        Shall act as the Head Agency for the Committee. And as such the Chairperson shall be the signing, the DHSUD
                                        Secretary shall be the signing authority for the Accreditation Certificate
                                    </p>
                                </div>
                            </div>

                            <div className='text-center p-2 text-white mt-5'style={{backgroundColor: "#21418c" , borderRadius: "7px"}}>
                                <h4>National Housing Authority (NHA)</h4>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-sm-4 text-center mt-3 mb-3'>
                                    <img src={NHA} className="product-image w-75 mt-3" alt="Product Image" style={{margin: "auto"}}/>
                                </div>

                                <div className='col-sm-8 mt-3 mb-3'>
                                    <label className='font-weight-normal'>Member:</label>
                                    <h5>ATTY. MARIA MAGDALENA D. SIACON</h5>

                                    <p className='text-muted mb-0 pt-3'>Head Secretariat</p>
                                    <h5>ENGR. JOVITA G. PANOPIO</h5>

                                    <p className='text-justify pt-3 text-md'><b>Function:</b> &nbsp;
                                        Shall act as the AITECH Secretariat for the Committee. The responsible agency involved with the structural and economic
                                        evaluation of new technologies system appropriate for socialized, economic medium and high-end housing project.
                                    </p>
                                </div>
                            </div>

                            <div className='text-center p-2 text-white mt-5'style={{backgroundColor: "#21418c" , borderRadius: "7px"}}>
                                <h4>Department of Science and Technology – Philippine Council for Industry, Energy and Emerging Technology Research and Development (DOST-PCIEERD)</h4>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-sm-4 text-center mt-3 mb-3'>
                                    <img src={DOST} className="product-image w-75" alt="Product Image" />
                                </div>

                                <div className='col-sm-8 mt-3 mb-3'>
                                    <label className='font-weight-normal'>Member:</label>
                                    <h5>ENGR. CHRISTIAN ALEC A. MAÑAGA</h5>

                                    <p className='text-muted mb-0 pt-3'>Alternate Representative:</p>
                                    <h5>-</h5>

                                    <p className='text-justify pt-3 text-md'><b>Function:</b> &nbsp;
                                        Recommends innovative materials/technologies for construction application; support Research and Development for housing technologies; and provide
                                        list of testing center amongst the DOST Research and Development Institutes and other established laboratory.
                                    </p>
                                </div>
                            </div>

                            <div className='text-center p-2 text-white mt-5'style={{backgroundColor: "#21418c" , borderRadius: "7px"}}>
                                <h4>Home Development Mutual Fund (HDMF)</h4>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-sm-4 text-center mt-3 mb-3'>
                                    <img src={PAGIBIG} className="product-image w-75" alt="Product Image" />
                                </div>

                                <div className='col-sm-8 mt-3 mb-3'>
                                    <label className='font-weight-normal'>Member:</label>
                                    <h5>ENGR. MARIA IMELDA E. PABROS, PH.D.</h5>

                                    <p className='text-muted mb-0 pt-3'>Alternate Representative:</p>
                                    <h5 className='text-uppercase'>Engr.  Ernie B. Paladan</h5>

                                    <p className='text-justify pt-3 text-md'><b>Function:</b> &nbsp;
                                        Accepts accredited technologies used in socialized housing.
                                    </p>
                                </div>
                            </div>

                            <div className='text-center p-2 text-white mt-5'style={{backgroundColor: "#21418c" , borderRadius: "7px"}}>
                                <h4>National Home Mortgage Finance Corporation (NHMFC)</h4>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-sm-4 text-center mt-3 mb-3'>
                                    <img src={NHMFC} className="product-image w-75" alt="Product Image" />
                                </div>

                                <div className='col-sm-8 mt-3 mb-3'>
                                    <label className='font-weight-normal'>Member:</label>
                                    <h5 className='text-uppercase'>Engr. Rubirosa M. Alvarado</h5>

                                    <p className='text-muted mb-0 pt-3'>Alternate Representative:</p>
                                    <h5 className='text-uppercase'>Engr. Christopher H. Tiongco</h5>

                                    <p className='text-justify pt-3 text-md'><b>Function:</b> &nbsp;
                                        Reviews and accepts accredited technologies for mortgage financing under UHLP.
                                    </p>
                                </div>
                            </div>

                            <div className='text-center p-2 text-white mt-5'style={{backgroundColor: "#21418c" , borderRadius: "7px"}}>
                                <h4>University of the Philippines – Building Research Service (UP-BRS)</h4>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-sm-4 text-center mt-3 mb-3'>
                                    <img src={UP} className="product-image w-75" alt="Product Image" />
                                </div>

                                <div className='col-sm-8 mt-3 mb-3'>
                                    <label className='font-weight-normal'>Member:</label>
                                    <h5 className='text-uppercase'>Dr. Fernando J. Germar</h5>

                                    <p className='text-muted mb-0 pt-3'>Alternate Representative:</p>
                                    <h5 className='text-uppercase'>Engr. Keren B. Estrellado</h5>

                                    <p className='text-justify pt-3 text-md'><b>Function:</b> &nbsp;
                                    Act as an alternative testing facility and evaluating agency concerned with the overall technical
                                    feasibility and structural integrity of technologies.
                                    </p>
                                </div>
                            </div>

                            <div className='text-center p-2 text-white mt-5'style={{backgroundColor: "#21418c" , borderRadius: "7px"}}>
                                <h4>Department of Public Works and Highways – Bureau of Research Standards (DPWH-BRS)</h4>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-sm-4 text-center mt-3 mb-3'>
                                    <img src={DPWH} className="product-image w-75" alt="Product Image" />
                                </div>

                                <div className='col-sm-8 mt-3 mb-3'>
                                    <label className='font-weight-normal'>Member:</label>
                                    <h5 className='text-uppercase'>ENGR. MARIEL L. DE LEON</h5>

                                    <p className='text-muted mb-0 pt-3'>Alternate Representative:</p>
                                    <h5 className='text-uppercase'>Dominic F. Lacson</h5>

                                    <p className='text-justify pt-3 text-md'><b>Function:</b> &nbsp;
                                        Act as an alternative evaluating agency on structural designs for one storey, medium to high rise housing;
                                        testing of materials pertaining to other technologies for infrastructure and validation of test results
                                        from accredited testing laboratories.
                                    </p>
                                </div>
                            </div>

                            <div className='text-center p-2 text-white mt-5'style={{backgroundColor: "#21418c" , borderRadius: "7px"}}>
                                <h4>Construction Industry Association of the Philippines (CIAP)</h4>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-sm-4 text-center mt-3 mb-3'>
                                    <img src={CIAP} className="product-image w-75" alt="Product Image" />
                                </div>

                                <div className='col-sm-8 mt-3 mb-3'>
                                    <label className='font-weight-normal'>Member:</label>
                                    <h5 className='text-uppercase'>Engr. Jocelyn C. Carrasco</h5>

                                    <p className='text-muted mb-0 pt-3'>Alternate Representative:</p>
                                    <h5 className='text-uppercase'>Engr. Nikki C. Najarro</h5>

                                    <p className='text-justify pt-3 text-md'><b>Function:</b> &nbsp;
                                        Act as an alternative evaluating agency on structural designs for one storey, medium to high rise housing;
                                        testing of materials pertaining to other technologies for infrastructure and validation of test results
                                        from accredited testing laboratories.
                                    </p>
                                </div>
                            </div>

                            <div className='text-center p-2 text-white mt-5'style={{backgroundColor: "#21418c" , borderRadius: "7px"}}>
                                <h4>Department of Trade and Industry – Bureau of Philippine Standards (DTI-BPS)</h4>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-sm-4 text-center mt-3 mb-3'>
                                    <img src={DTI} className="product-image w-75" alt="Product Image" />
                                </div>

                                <div className='col-sm-8 mt-3 mb-3'>
                                    <label className='font-weight-normal'>Member:</label>
                                    <h5 className='text-uppercase'>Engr. Maria Teresita G. Del Rosario</h5>

                                    <p className='text-muted mb-0 pt-3'>Alternate Representative:</p>
                                    <h5>-</h5>

                                    <p className='text-justify pt-3 text-md'><b>Function:</b> &nbsp;
                                    Tasked with information dissemination on new construction materials as well as the development standards
                                    </p>
                                </div>
                            </div>
                        </div>


                    </section>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default TheCommittee
import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

const OtherReferences = () => {
  return (
    <div>
        <Header/>
        <div className="content-wrapper bg-white">
            <div className="content-header">
                <div className="container"></div>
            </div>
            
            <br/><br/>
            <div className='mt-3 text-center'>
                <h4 className=''>Directory of Accredited Private Testing Laboratories</h4>
                <a href='https://www.dpwh.gov.ph/dpwh/business/accreditation/materials_testing_laboratories/list'>https://www.dpwh.gov.ph/dpwh/business/accreditation/materials_testing_laboratories/list</a>

                <h4 className='mt-5'>Official List of Members July 2023-June 2024</h4>
                <a href='https://aseponline.org/membership/'>https://aseponline.org/membership/</a>
            </div>
        </div>
        <Footer /> 
    </div>
  )
}

export default OtherReferences
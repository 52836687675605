import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import * as BsIcons from "react-icons/bs";

const AccreditedTechnologies = () => {
  return (
    <div>
        <Header />
        <div className="content-wrapper bg-white">
            <div className="content-header">
                <div className="container">
                    <div>
                        <h3 className='pt-5'>List of AITECH Accredited Technologies</h3>
                        <p className='text-muted'>Please refer to the AITECH Manual for technology description and contact information.</p>
                    </div>

                    <div>
                    {/* <div className='mt-2 mb-2'>
                        <div className="input-group w-25" style={{width: 200}}>
                                    <input type="text" name="table_search" className="form-control float-right" placeholder="Search" />
                                    <div className="input-group-append">
                                    <button type="submit" className="btn btn-default">
                                        <BsIcons.BsSearch/>
                                    </button>
                                    </div>
                        </div>
                    </div> */}
                    <div className="card" style={{border: 'solid 1px #e9ebec'}}>
                            <div className="card-body table-responsive p-0" style={{height: 400}}>
                                <table className="table table-head-fixed table-hover text-wrap">
                                <thead>
                                    <tr>
                                        <th style={{width:'5px'}}>#</th>
                                        <th>PROPONENT</th>
                                        <th>TECHNOLOGY</th>
                                        <th>DESCRIPTION</th>
                                        <th>VALIDITY</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>AAC Lightweight Block Corp.</td>
                                        <td>AAC by Lightstrong Block</td>
                                        <td>for walls only</td>
                                        <td>April 29, 2025</td>
                                    </tr>

                                    <tr>
                                        <td>2</td>
                                        <td>Acasys Development Corporation</td>
                                        <td>Acasys Wall System</td>
                                        <td>for walls only</td>
                                        <td>June 23, 2024</td>
                                    </tr>

                                    <tr>
                                        <td>3</td>
                                        <td>Accutech Steel and Service Center, Inc.</td>
                                        <td>Light Gauge Steel Frame</td>
                                        <td>for one to two-storey structure only</td>
                                        <td>August 1, 2024</td>
                                    </tr>

                                    <tr>
                                        <td>4</td>
                                        <td>Agtekpin Bricks Manufacturing</td>
                                        <td>Agtekpin Bricks</td>
                                        <td>for walls only</td>
                                        <td>September 7, 2025</td>
                                    </tr>

                                    <tr>
                                        <td>5</td>
                                        <td>Alakor Corporation</td>
                                        <td>Waffle-Crete Precast Reinforced Cement Structure</td>
                                        <td>or one to five storey structure only</td>
                                        <td>February 22, 2027</td>
                                    </tr>

                                    <tr>
                                        <td>6</td>
                                        <td>Architecks Metal Systems, Inc.</td>
                                        <td>Concrete Floor Steel Decking</td>
                                        <td>for walls only</td>
                                        <td>August 1, 2026</td>
                                    </tr>

                                    <tr>
                                        <td>7</td>
                                        <td>ARSS Construction Materials Trading</td>
                                        <td>ARRS GFRP Supreme</td>
                                        <td>steel replacement/ materials only</td>
                                        <td>February 22, 2027</td>
                                    </tr>

                                    <tr>
                                        <td>8</td>
                                        <td>AV88 Corporation</td>
                                        <td>M-Panel</td>
                                        <td>for walls and fence only</td>
                                        <td>April 29, 2025</td>
                                    </tr>

                                    <tr>
                                        <td>9</td>
                                        <td>Barnpro Prefab Buildings Corp.</td>
                                        <td>Light Gauge Steel (LGS)</td>
                                        <td>for one-storey structure only</td>
                                        <td>June 1, 2026</td>
                                    </tr>

                                    <tr>
                                        <td>10</td>
                                        <td>Base Bahay Foundation, Inc.</td>
                                        <td>Cement Bamboo Frame Technology</td>
                                        <td>for one storey structure only</td>
                                        <td>September 7, 2024</td>
                                    </tr>

                                    <tr>
                                        <td>11</td>
                                        <td>BEC Specialist Inc.</td>
                                        <td>Safe Affordable Modular Housing Technology (SAM-HT)</td>
                                        <td>for one to five-storey structure only</td>
                                        <td>June 1, 2024</td>
                                    </tr>

                                    <tr>
                                        <td>12</td>
                                        <td>Bestank Manufacturing Corp.</td>
                                        <td>Filtering Septic Tank</td>
                                        <td>for (3) three chamber septic tank</td>
                                        <td>June 23, 2024</td>
                                    </tr>

                                    <tr>
                                        <td>13</td>
                                        <td>Cavite Form Modules Inc.</td>
                                        <td>JJM Wall Formtile</td>
                                        <td>for one-storey structure only</td>
                                        <td>June 1, 2026</td>
                                    </tr>

                                    <tr>
                                        <td>14</td>
                                        <td>China-Philippines Wall Tech Systems, Inc.</td>
                                        <td>Fahstwall</td>
                                        <td>for walls only</td>
                                        <td>June 1, 2026</td>
                                    </tr>

                                    <tr>
                                        <td>15</td>
                                        <td>Community Developers and Construction Corporation</td>
                                        <td>Tunnel Form System</td>
                                        <td>for one to fifteen-storey structure only</td>
                                        <td>June 1, 2026</td>
                                    </tr>

                                    <tr>
                                        <td>16</td>
                                        <td>Connovate Philippines, Inc.</td>
                                        <td>Connovate Pre-cast Wall Panel</td>
                                        <td>for one to two-storey structure only</td>
                                        <td>April 29, 2025</td>
                                    </tr>

                                    <tr>
                                        <td>17</td>
                                        <td>Dasco Global Construction and Development Corp.</td>
                                        <td>Prefabricated Post-Tensioned Superstructure System</td>
                                        <td>for one to five storey structure only</td>
                                        <td>June 23, 2024</td>
                                    </tr>

                                    <tr>
                                        <td>18</td>
                                        <td>Earth Based Habitat Builders and Integrated Services, Inc.</td>
                                        <td>San Carlos Philippine Enviro-blox</td>
                                        <td>for one to two-storey structure only</td>
                                        <td>August 1, 2026</td>
                                    </tr>

                                    <tr>
                                        <td>19</td>
                                        <td>Ecostorage Solutions Inc.</td>
                                        <td>Plastic Septic Tank</td>
                                        <td>for three (3) chamber septic tank in compliance with DENR Standards of DAO No. 2016-08 and 2021-19 Water Quality Guidelines and General Effluent Standards</td>
                                        <td>June 1, 2026</td>
                                    </tr>

                                    <tr>
                                        <td>20</td>
                                        <td>EZ Block Corporation</td>
                                        <td>Foam Concrete Block</td>
                                        <td>for walls only</td>
                                        <td>August 1, 2024</td>
                                    </tr>

                                    <tr>
                                        <td>21</td>
                                        <td>Forthress Philippines, Corporation</td>
                                        <td>Forthress Lightweight Precast Concrete Panel</td>
                                        <td>for walls only</td>
                                        <td>June 1, 2024</td>
                                    </tr>

                                    <tr>
                                        <td>22</td>
                                        <td>Graditel Construction Corporation</td>
                                        <td>Graditel Wall System</td>
                                        <td>for one to two-storey structure only</td>
                                        <td>June 1, 2026</td>
                                    </tr>

                                    <tr>
                                        <td>23</td>
                                        <td>Hausland Development Corp./ Fiesta Communities Inc.</td>
                                        <td>Load Bearing Walls (CHB)</td>
                                        <td>for one to two-storey structure only</td>
                                        <td>August 28, 2023</td>
                                    </tr>

                                    <tr>
                                        <td>24</td>
                                        <td>Jacintocolor Steel, Inc.</td>
                                        <td>Typhoon-Resilient Pre-fabricated Steel Frame Housing</td>
                                        <td>for one-storey structure only</td>
                                        <td>April 29, 2025</td>
                                    </tr>

                                    <tr>
                                        <td>25</td>
                                        <td>James Hardie Philippines, Inc.</td>
                                        <td>Hardieflex Board</td>
                                        <td>for cladding only</td>
                                        <td>November 15, 2024</td>
                                    </tr>

                                    <tr>
                                        <td>26</td>
                                        <td>JF Ledesma Foundation Inc.</td>
                                        <td>San Carlos Philippine Enviro-blox</td>
                                        <td>for one-storey structure with loft only</td>
                                        <td>April 29, 2025</td>
                                    </tr>

                                    <tr>
                                        <td>27</td>
                                        <td>Liciada Innovations Inc.</td>
                                        <td>FCS Bricks</td>
                                        <td>for one-storey structure only</td>
                                        <td>November 15, 2024</td>
                                    </tr>

                                    <tr>
                                        <td>28</td>
                                        <td>Mahanaim Consulting and Development Inc.</td>
                                        <td>Armastek Glass Fiber Reinforced Polymer</td>
                                        <td>steel replacement/ materials only</td>
                                        <td>February 22, 2027</td>
                                    </tr>

                                    <tr>
                                        <td>29</td>
                                        <td>Megawide Construction Corporation</td>
                                        <td>Prefabricated Concrete Products (Building Components)</td>
                                        <td>for one to fourteen structure only</td>
                                        <td>August 1, 2026</td>
                                    </tr>

                                    <tr>
                                        <td>30</td>
                                        <td>Penta-Continental Wall Panel Systems Incorporated</td>
                                        <td>QuickPanel Lightweight Wall Panel</td>
                                        <td>for walls only</td>
                                        <td>June 1, 2026</td>
                                    </tr>

                                    <tr>
                                        <td>31</td>
                                        <td>Pioneer Specialty Building Systems, Inc.</td>
                                        <td>Pioneer Ecosips (Fiber Cement Board Skin)</td>
                                        <td>for one-storey with loft structure only</td>
                                        <td>June 1, 2024</td>
                                    </tr>

                                    <tr>
                                        <td>32</td>
                                        <td>RC Solid Star Construction Corporation</td>
                                        <td>RC Wall</td>
                                        <td>for walls only</td>
                                        <td>June 1, 2026</td>
                                    </tr>

                                    <tr>
                                        <td>33</td>
                                        <td>Schimdt Konstruktion Systems Corp.</td>
                                        <td>SKS Structural Steel Framing System</td>
                                        <td>for one storey structure only</td>
                                        <td>September 7, 2024</td>
                                    </tr>

                                    <tr>
                                        <td>34</td>
                                        <td>Sejong Plastic Mfg. Corp.</td>
                                        <td>3 Chamber Plastic Septic Tank</td>
                                        <td>for three (3) chamber septic tank in compliance with DENR Standards of DAO No. 2016-08 and 2021-19 Water Quality Guidelines and General Effluent Standards</td>
                                        <td>February 22, 2027</td>
                                    </tr>

                                    <tr>
                                        <td>35</td>
                                        <td>Silvermane Impex and Global Trading Corp.</td>
                                        <td>Hekimpan Fibercement EPS Wall Panel</td>
                                        <td>for walls only</td>
                                        <td>June 1, 2024</td>
                                    </tr>

                                    <tr>
                                        <td>36</td>
                                        <td>SRC Int'l Panel System, Inc.</td>
                                        <td>SRC Panel</td>
                                        <td>for walls only</td>
                                        <td>February 22, 2027</td>
                                    </tr>

                                    <tr>
                                        <td>37</td>
                                        <td>Starboard Foundation Inc.</td>
                                        <td>Eco Blocks</td>
                                        <td>for walls only</td>
                                        <td>November 15, 2024</td>
                                    </tr>

                                    <tr>
                                        <td>38</td>
                                        <td>Starken Philippines, Inc.</td>
                                        <td>Autoclaved Aerated Concrete (AAC)</td>
                                        <td>for one to four-storey structure only</td>
                                        <td>June 1, 2026</td>
                                    </tr>

                                    <tr>
                                        <td>39</td>
                                        <td>Sterling Construction and Development Corporation</td>
                                        <td>Plaswall</td>
                                        <td>for one to three-storey structure only</td>
                                        <td>June 1, 2026</td>
                                    </tr>

                                    <tr>
                                        <td>40</td>
                                        <td>Successful Gains TRading Corp.</td>
                                        <td>D'ez Blox</td>
                                        <td>for walls only</td>
                                        <td>August 1, 2026</td>
                                    </tr>

                                    <tr>
                                        <td>41</td>
                                        <td>SYU Group Systech Corp.</td>
                                        <td>Polywall EPS Lightweight Concrete Panel</td>
                                        <td>for walls only</td>
                                        <td>June 1, 2026</td>
                                    </tr>

                                    <tr>
                                        <td>42</td>
                                        <td>Unimore Trading Corp.</td>
                                        <td>Pre-Engineered and Pre-Fabricated Housing System</td>
                                        <td>for one to two storey structure only</td>
                                        <td>November 15, 2024</td>
                                    </tr>

                                    <tr>
                                        <td>43</td>
                                        <td>Unimore Trading Corp.</td>
                                        <td>Modular House</td>
                                        <td>for one-storey structure only</td>
                                        <td>June 1, 2024</td>
                                    </tr>

                                    <tr>
                                        <td>44</td>
                                        <td>Yunhap Plastic Corporation</td>
                                        <td>Plastic Three Chamber Purifying Septic</td>
                                        <td>DENR Standards of DAO No. 08 Water Quality Guidelines and General Effluent Standards of 2016</td>
                                        <td>September 7, 2024</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>

    </div>
  )
}

export default AccreditedTechnologies
import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import AM from "../dist/img/aitectmanual.jpg"
import AitechManual from "../dist/files/AitechManual2024.pdf"

const Objectives = () => {
  return (
    <div>
        <Header />
        <div className="content-wrapper bg-white">
            <div className="content-header">
                <div className="container">
                    <div>
                        <h3 className='pt-5'>The AITECH Manual</h3>
                    </div>
                    <div className='mt-4'>
                        <p className='text-justify text-mb'>
                            <b>AITECH</b> stands for Accreditation of Innovative Technologies for Housing. It is a system for accrediting innovative technologies  for housing.
                            AITECH was conceived as a result of the numerous building technologies that have been developed in the last few years using non-traditional
                            or alternative materials and systems with significant reductions in construction costs (thereby ensuring affordability) as opposed to the use
                            of conventional materials and systems <b><i>(e.g. concrete hollow blocks)</i></b>. Cognizant of their potential in reducing over-all housing costs, it has
                            become imperative to accredit these innovative technologies for low-cost housing.
                        </p>

                        <p className='text-justify text-mb' >
                            AITECH responds to queries and requests from private manufacturers of indigenous and innovative building materials/systems for <b><u>technical and engineering
                            evaluation</u></b> leading to accreditation of these products. The <b><u>AITECH Inter-Agency Committee</u></b> reviews and accredits the technologies.
                        </p>
                    </div>
                    <br/>
                    <div className='mt-4 mb-4'>
                        <div className='row'>

                            <div className='col-sm-4 text-center'>
                                <div className='text-center' style={{border: 'solid 1px #ccc', borderRadius: "5px"}}>
                                    <img src={AM} alt="AITECH Manual" className='w-100 p-3'/>
                                    <p className='text-left pl-3'>
                                        Click <a href={AitechManual} target="blank">here</a> to view AITECH Manual 2024
                                    </p>
                                </div>
                            </div>

                            <div className='col-sm-8 text-center'>
                                <div className='h-100 p-4' style={{border: 'solid 1px #ccc', borderRadius: "5px"}}>
                                    <h4 className='mt-5'>The AITECH Manual</h4>
                                    <p className='pt-3'>
                                        Consists of the AITECH Background, Objectives, Functions and Roles of the AITECH Committee Members, Technology Definition, Steps in
                                        Accreditation, Process Flow Chart, Checklist of Requirements and the  List of Accredited Technologies with Technology and Contact Information 
                                    </p>
                                </div>
                            </div>

                            {/* <div className='col-sm-6' style={{border: '1px solid grey'}}>
                                <h4 className='w-100'>The AITECH Manual</h4>
                                <p><i>
                                    Consists of the AITECH Background, Objectives, Functions and Roles of the AITECH Committee Members,
                                    Technology Definition, Steps in Accreditation, Process Flow Chart, Checklist of Requirements and the 
                                    List of Accredited Technologies with Technology and Contact Information 
                                </i></p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Objectives